import React, { Component } from 'react'
import Landing from '../../../../components/case-studies/Landing'
import AboutProject from '../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../components/case-studies/FontShowcase'
import Footer from '../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../components/reveal'
import ProjectImageLightBox from '../../../../components/case-studies/ProjectImageLightBox'

class Cario extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter


    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Vytvorenie mobilnej aplikácie Cario'}
          /*           description={'www.flipwork.sk'}
                    link={'https://www.flipwork.sk/'} */
          description2={'Tvorba aplikácie iOS a Android pre slovenský start-up lokálneho “uberu” vrátane UX a kompletného brandingu.'}
          text={'Výsledkom našej práce je koncept modernej appky spájajúcej šoférov a záujemcov o jazdu, ktorí potrebujú odviezť a hľadajú rýchle a flexibilné riešenie. Projekt je efektívnou a pohodlnou voľbou pre obidve strany. '}
          img={data.landingImage}
          what_we_done={[
            { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/cario-branding' },
            { text: 'ux & app design' },
            { text: 'microsite', link: '/nase-prace/webstranky/microsite/cario' },
            { text: 'mobilná aplikácia', link: '/nase-prace/aplikacie/cario-app' },
          ]} />

        <AboutProject
          title={'Proces tvorby mobilnej aplikácie na mieru'}
          text={'Moderná a funkčná appka si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Boli sme oslovení na vytvorenie <a class="blue-link" href="/tvorba-mobilnych-aplikacii">mobilnej appky</a>, ktorá pomôže ľuďom jednoducho a rýchlo nájsť odvoz vďaka intuitívnemu UX aplikácie, v ktorej sa budú návštevníci ľahko orientovať. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Klientovym cieľom bol jednoduchý a zapamätateľný vizuál so živými farbami a <a class="blue-link" href="/ux-ui-dizajn">logickým rozložením</a> appky. Zamerali sme sa preto na kompletne nový branding, prepracované UX a dizajn aplikácie a zároveň najlepšiu technologickú úroveň <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Ďalej sa navrhli <a class="blue-link" href="/tvorba-loga">varianty loga</a> a celá <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme nakreslili wireframy (rozloženia stránok) a finálny dizajn aplikácie. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme pripravili na finálne programovanie.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením appky optimalizujeme, vylaďujeme detailne responzívnosť a všetko finálne testujeme. Odovzdaním spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Samozrejmosťou pre programovanie Android, iOS či multiplatformových appiek je <b>spoľahlivý servis a optimalizácia</b>, aby appky išli s dobou. ',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#df542f', tintColorCode: '#e57659', nameColor: '#FFFFFF', name: 'Oranžová' },
          { code: '#eb8c2d', tintColorCode: '#efa357', nameColor: '#FFFFFF', name: 'Mandarinková' },
          { code: '#20204b', tintColorCode: '#4d4d6f', nameColor: '#FFFFFF', name: 'Oxfordská modrá' },
          { code: '#276aa6', tintColorCode: '#5288b8', nameColor: '#FFFFFF', name: 'Lapis lazuli' },
        ]} />

        <FontShowcase
          fontName={'ITC Avant Garde Gothic'}
          text={'ITC Avant Garde Gothic is rodina písiem založená na logo-písme použitom v magazíne Avant Garde. Herb Lubalin navrhol logo koncept a jeho spoločník navrhol písmo nadpisu, potom spolu s Tomom Carnasom, parnterom v Lubalinovej dizajnovej firme, spoločne pracovali na transformácii idey do plnohodnotného typu písma.<br><br>Zhustený rez bol nakreslený Edom Benguiatom v roku 1974 a šikmé verzie boli navrhnuté Andrém Gürtlerom, Erichom Gschwindom a Christianom Mengeltom v roku 1977.'}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/itc-avant-garde-gothic.svg'}
        />

        <Reveal>
          <div className={'container p-0'}>
            <Img className={'mb-5'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
          </div>
        </Reveal>

        <PagesShowcase
          fixedTitleWidth={true}
          title={'Dizajn aplikácie na mieru'}
          description={'Vývoj mobilných aplikácií sa bez neho nezaobíde.'}
          text={'Moderný dizajn obsahuje všetko, čo by mala moderná appka spĺňať. Nielen jedinečnosť a estetickosť, ale aj responzivita, intuitívne používateľské rozhranie, prehľadnosť a funkčnosť sú atribúty, ktoré používateľ očakáva od mobilnej aplikácie, ktorú bude pravidelne využívať.'}
          projectDetail={'/nase-prace/aplikacie/cario-app'}
          pages={[
            {
              name: 'Loading',
              image: data.pageOne,
            },
            {
              name: 'Výber služby',
              image: data.pageTwo,
            },
            {
              name: 'Login',
              image: data.pageThree,
            },
            {
              name: 'Menu',
              image: data.pageFour,
            },
            {
              name: 'Mapa',
              image: data.pageSix,
            },
            {
              name: 'Destinácia',
              image: data.pageSeven,
            },
            {
              name: 'Vozidlá',
              image: data.pageEight,
            },
            {
              name: 'Pasažieri',
              image: data.pageNine,
            }
          ]} />

        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="letenky" />
          </div>
        </Reveal>

        <div className={'dark-section'}>
          <Reveal>
            <div className={'container wrapper pb-0'}>
              <div className={'row'}>
                <div className={'col-md-7'}>
                  <h2 className={'cs-title'}>Dizajn mobilnej aplikácie</h2>
                  <p className="description">Dokonalé UI a UX v každom jednom detaile.</p>
                  <p className="description-smaller">Vytvoriť responzívnu appku do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='red-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na všetkých typoch smartfónov, tabletov a ďalších rozlíšeniach. <br /><br />Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastnú appku a chce, aby mu prinášala čo najväčší zisk.</p>
                </div>
                <div className={'col-md-5'}>
                  <Img alt={this.constructor.name} fluid={data.icon.childImageSharp.fluid} />
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-md-4 cario img-col-1'}>
                  <Img fluid={data.screenOne.childImageSharp.fluid} alt={this.constructor.name} />
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenTwo.childImageSharp.fluid} />
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenThree.childImageSharp.fluid} />
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenFour.childImageSharp.fluid} />
                </div>
                <div className={'col-md-4 cario img-col-2'}>
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenFive.childImageSharp.fluid} />
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenSix.childImageSharp.fluid} />
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenSeven.childImageSharp.fluid} />
                </div>
                <div className={'col-md-4 cario img-col-3'}>
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenEight.childImageSharp.fluid} />
                  <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenNine.childImageSharp.fluid} />
                </div>
              </div>
              <div className={'row'}>
                <Img className={'col-md-6 offset-md-6 cario-hand'} alt={this.constructor.name} fluid={data.hand.childImageSharp.fluid} />
              </div>

            </div>
          </Reveal>
        </div>

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Cliqdate'}
          nextProjectUrl={'/nase-prace/pripadove-studie/aplikacie/cliqdate/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
    project: markdownRemark(fields: {slug: {regex: "/cario_app/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/Cario_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/cr1.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/cr2.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/cr3.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/cr4.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFive: file(relativePath: { eq: "case-studies/cr5.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSix: file(relativePath: { eq: "case-studies/cr6.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSeven: file(relativePath: { eq: "case-studies/cr7.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageEight: file(relativePath: { eq: "case-studies/cr8.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageNine: file(relativePath: { eq: "case-studies/cr9.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTen: file(relativePath: { eq: "case-studies/1_carioapp.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageEleven: file(relativePath: { eq: "case-studies/2_carioapp.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/Cario_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/cario_CI.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    icon: file(relativePath: { eq: "case-studies/cario_ikona.png" }) {
        childImageSharp {
            fluid( maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenOne: file(relativePath: { eq: "case-studies/Cario_screenOne.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenTwo: file(relativePath: { eq: "case-studies/Cario_screenTwo.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenThree: file(relativePath: { eq: "case-studies/Cario_screenThree.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenFour: file(relativePath: { eq: "case-studies/Cario_screenFour.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenFive: file(relativePath: { eq: "case-studies/Cario_screenFive.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenSix: file(relativePath: { eq: "case-studies/Cario_screenSix.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenSeven: file(relativePath: { eq: "case-studies/Cario_screenSeven.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenEight: file(relativePath: { eq: "case-studies/Cario_screenEight.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenNine: file(relativePath: { eq: "case-studies/Cario_screenNine.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    hand: file(relativePath: { eq: "case-studies/Cario_hand.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Cario
